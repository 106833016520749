<template>

<com-content :blue="true" class="intro">

	<com-content :inner="true">

		<h1>Adjustable pricing tailored to your needs</h1>

		<p>Pick which services you will use to customise your pricing.</p>

		<div class="intro-steps">

			<div class="intro-steps-item">
				<div class="intro-steps-item-icon"><i class="fa-solid fa-id-card-clip"></i></div>
				<div class="intro-steps-item-text"><span>Badge fee</span><br/>Customisable<small>see below for details</small></div>
			</div>

			<div class="intro-steps-item is-plus">+</div>

			<div class="intro-steps-item">
				<div class="intro-steps-item-icon"><i class="fa-solid fa-cart-shopping"></i></div>
				<div class="intro-steps-item-text"><span>Sales fee*</span><br/>4.0%<small>*when applicable w/min. ${{ $platform.billing.charge.min }}</small></div>
			</div>

		</div>

	</com-content>

</com-content>
	
</template>

<script>

export default {

	components: {

		'com-content': () => import('./../common/Content')

	}

}

</script>

<style scoped>

.intro {
	padding: 130px 0px 40px 0px;
}

.is-mobile .intro {
	padding: 80px 0px 50px 0px;
}

.intro:after {
	content: '';
	display: block;
	width: 0;
	height: 0;
	border-style: solid;
	border-width: 24px 32px 0 32px;
	border-color: #1e3054 transparent transparent transparent;
	position: absolute;
	bottom: -24px;
	left: 50%;
	margin-left: -32px;
}

.intro h1 {
	font-size: 28px;
	line-height: 28px;
    font-weight: 500;
	padding: 0px 10px;
    text-align: center;
    color: #fff;
}

.is-mobile .intro h1 {
	font-size: 24px;
}

.intro h2 {
	font-size: 16px;
	margin-top: 40px;
	padding: 0px 10px;
    font-weight: 400;
    text-align: center;
    color: #fff;
}

.intro p {
	font-size: 16px;
	line-height: 20px;
	font-weight: 300;
	padding: 0px 10px;
	margin-top: 8px;
    text-align: center;
    color: #fff;
	margin-bottom: 40px;
}

.intro-steps {
	display: flex;
	align-items: top;
}

.intro-steps-item {
	color: #fff;
	display: flex;
	flex-direction: column;
	align-items: center;
	margin: 0px 20px;
}

.is-mobile .intro-steps-item {
	margin: 0px;
}

.intro-steps-item.is-plus {
	font-size: 32px;
	line-height: 48px;
}

.intro-steps-item-icon {
	font-size: 48px;
	line-height: 48px;
}

.intro-steps-item-text {
	text-align: center;
	margin-top: 10px;
	font-size: 16px;
	font-weight: 400;
}

.intro-steps-item-text span {
	background-color: #ffbe7a;
	color: #333;
	padding: 2px 4px;
	border-radius: 4px;
	display: inline-block;
	margin-bottom: 4px;
}

.intro-steps-item-text small {
	font-size: 10px;
	opacity: 0.5;
	font-weight: 300;
	display: block;
	margin-top: 4px;
}

</style>